import React from "react"
import Box from "./box"
import PropTypes from "prop-types"

const HalfAssedBox = ({ children }) => {
  return (
    <Box
      sx={{
        borderTopWidth: 4,
        borderLeftWidth: 4,
        borderStyle: "solid",
        borderColor: "border",
        position: "relative",
        py: [6, 6, 8],
        pl: [6, 6, 8],
      }}
    >
      {children}
    </Box>
  )
}

HalfAssedBox.propTypes = {
  children: PropTypes.node,
}

export default HalfAssedBox
